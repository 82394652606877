import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ContentsList from '../../../components/ContentsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Amebaブランド" mdxType="SEO" />
    <PageTitle title="Amebaブランド" enTitle="Ameba Brand" mdxType="PageTitle" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a4784763cf09c8f453bd83ef43f6668a/f09ab/ameba-brand-index.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.12500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a4784763cf09c8f453bd83ef43f6668a/2aaaf/ameba-brand-index.webp 160w", "/static/a4784763cf09c8f453bd83ef43f6668a/85e47/ameba-brand-index.webp 320w", "/static/a4784763cf09c8f453bd83ef43f6668a/75198/ameba-brand-index.webp 640w", "/static/a4784763cf09c8f453bd83ef43f6668a/691bc/ameba-brand-index.webp 960w", "/static/a4784763cf09c8f453bd83ef43f6668a/65e4a/ameba-brand-index.webp 1250w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a4784763cf09c8f453bd83ef43f6668a/69538/ameba-brand-index.png 160w", "/static/a4784763cf09c8f453bd83ef43f6668a/72799/ameba-brand-index.png 320w", "/static/a4784763cf09c8f453bd83ef43f6668a/6af66/ameba-brand-index.png 640w", "/static/a4784763cf09c8f453bd83ef43f6668a/d9199/ameba-brand-index.png 960w", "/static/a4784763cf09c8f453bd83ef43f6668a/f09ab/ameba-brand-index.png 1250w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a4784763cf09c8f453bd83ef43f6668a/6af66/ameba-brand-index.png",
              "alt": "「Ameba」の文字の下に7人の人と植物が描かれている。",
              "title": "「Ameba」の文字の下に7人の人と植物が描かれている。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Amebaのブランド = Amebaらしさをユーザーに届けるためには、まず「Amebaらしさ」が何であるかを理解しなければいけません。`}</p>
    <p>{`この項目では、Amebaが目指すところやその価値観について説明しています。`}</p>
    <ContentsList list={[{
      url: "/brand/ameba-brand/vision-and-mission/",
      title: "ビジョンとミッション",
      enTitle: "Vision and Mission",
      summary: "Amebaのビジョン、ミッション、バリューを定義しています。"
    }, {
      url: "/brand/ameba-brand/brand-guidelines/",
      title: "ブランドガイドライン",
      enTitle: "Brand Guidelines",
      summary: "Amebaのサービスバリュー、ブランドパーソナリティからAmebaらしい声質やトーン、言葉選びをブランドボイスとして定義しています。"
    }, {
      url: "/brand/ameba-brand/slide-templates/",
      title: "スライドテンプレート",
      enTitle: "Slide Templates",
      summary: "利用規定に従ったロゴや、Amebaらしさとしてのグラフィックが盛り込まれたスライドテンプレートを掲載しています。"
    }, {
      url: "/brand/ameba-brand/resources/",
      title: "関連資料",
      enTitle: "Resources",
      summary: "ブランドに関する関連資料を掲載しています。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      